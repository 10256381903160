import { CategoryName } from 'types/common'

export const createQueryParams = (
  params?: Record<string, string | number | boolean | Array<string>>,
): string => {
  if (!params) {
    return ''
  }

  const queryParams = new URLSearchParams()
  Object.entries(params)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => !!value)
    .forEach(([key, value]) => {
      queryParams.set(key, String(value))
    })

  return queryParams.toString()
}

export const withQueryParams = (
  url: string,
  queryParams?: Record<string, string | number | boolean | Array<string>>,
) => {
  const queryString = createQueryParams(queryParams)
  return queryString ? `${url}?${queryString}` : url
}

export const RouteNames = {
  categories: 'categories',
  favoriteResources: 'favorite-resources',
  learn: 'learn',
  courses: 'courses',
  recipes: 'recipes',
  mealPlans: 'meal-plans',
  newMealPlan: 'new-meal-plan',
  resourceLibrary: 'resource-library',
  resources: 'resources',
  tags: 'tags',
  admin: 'admin',
  signIn: 'sign-in',
  member: 'member',
  error: 'error',
  newCourse: 'new-course',
  newRecipe: 'new-recipe',
  newResource: 'new-resource',
  resourcePack: 'resource-pack',
  newResourcePack: 'new-resource-pack',
  resourcePacks: 'resource-packs',
  announcements: 'announcements',
  newAnnouncement: 'new-announcement',
  communityNews: 'community-news',
}

export const Pathnames = {
  userCourseDetail: `/${RouteNames.courses}/[id]`,
  resourcePackDetail: `/${RouteNames.resourcePack}/[id]`,
}

export const Routes = {
  Home: () => '/',
  Learn: () => `/${RouteNames.learn}`,
  ResourceLibrary: () => `/${RouteNames.resourceLibrary}`,
  FavoriteResources: () => `/${RouteNames.favoriteResources}`,
  Resources: () => `/${RouteNames.resources}`,
  Categories: () => `/${RouteNames.categories}`,
  Tags: () => `/${RouteNames.tags}`,
  CourseDetail: ({ id, ...params }: { id: string; section?: string }) =>
    withQueryParams(`/${RouteNames.courses}/${id}`, params),
  ResourcePackDetail: ({ id, ...params }: { id: string; section?: string }) =>
    withQueryParams(`/${RouteNames.resourcePack}/${id}`, params),
  CommunityNews: () => `/${RouteNames.communityNews}`,
  Admin: {
    Learn: () => `/${RouteNames.admin}/${RouteNames.learn}`,
    Recipes: () => `/${RouteNames.admin}/${RouteNames.recipes}`,
    RecipeDetail: ({ id }: { id: string }) => `/${RouteNames.admin}/${RouteNames.recipes}/${id}`,
    ResourceLibrary: () => `/${RouteNames.admin}/${RouteNames.resourceLibrary}`,
    Categories: () => `/${RouteNames.admin}/${RouteNames.categories}`,
    Tags: () => `/${RouteNames.admin}/${RouteNames.tags}`,
    CourseDetail: ({ id }: { id: string }) => `/${RouteNames.admin}/${RouteNames.courses}/${id}`,
    ResourceDetail: ({ id }: { id: string }) =>
      `/${RouteNames.admin}/${RouteNames.resources}/${id}`,
    NewCourse: () => `/${RouteNames.admin}/${RouteNames.newCourse}`,
    NewRecipe: (params?: { id?: string }) =>
      params?.id
        ? `/${RouteNames.admin}/${RouteNames.newRecipe}?id=${params.id}`
        : `/${RouteNames.admin}/${RouteNames.newRecipe}`,
    NewResource: () => `/${RouteNames.admin}/${RouteNames.newResource}`,
    NewResourcePack: () => `/${RouteNames.admin}/${RouteNames.newResourcePack}`,
    ResourcePackDetail: ({ id }: { id: string }) =>
      `/${RouteNames.admin}/${RouteNames.resourcePacks}/${id}`,
    Announcements: () => `/${RouteNames.admin}/${RouteNames.announcements}`,
    AnnouncementDetail: ({ id }: { id: string }) =>
      `/${RouteNames.admin}/${RouteNames.announcements}/${id}`,
    MealPlanDetail: ({ id }: { id: string }) =>
      `/${RouteNames.admin}/${RouteNames.mealPlans}/${id}`,
    NewAnnouncement: () => `/${RouteNames.admin}/${RouteNames.newAnnouncement}`,
    MealPlans: () => `/${RouteNames.admin}/${RouteNames.mealPlans}`,
    NewMealPlan: (params?: { id?: string }) =>
      params?.id
        ? `/${RouteNames.admin}/${RouteNames.newMealPlan}?id=${params.id}`
        : `/${RouteNames.admin}/${RouteNames.newMealPlan}`,
    WeeklyPlanner: ({ id }: { id: string }) =>
      `/${RouteNames.admin}/${RouteNames.mealPlans}/${id}/planner`,
  },
  SignIn: () => `/${RouteNames.signIn}`,
  Member: () => `/${RouteNames.member}`,
  Error: () => `/${RouteNames.error}`,
}

export const queryParams = {
  learn: CategoryName.Learn,
  resourceLibrary: CategoryName.ResourceLibrary,
  contentItem: 'contentItem',
  order: 'order',
  activeId: 'activeId',
  type: 'type',
  page: 'page',
  tags: 'tags',
  categories: 'categories',
  contentItemPreviewId: 'contentItemPreviewId',
  pageSize: 'pageSize',
}

export const ExternalLinks = {
  mealPlanner: 'https://www.livingplaterx.com/distributor/dashboard',
  community: 'https://www.facebook.com/groups/scaleyournutritionpractice',
  account: 'https://www.livingplaterx.com/distributor/account',
}
