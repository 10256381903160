import crypto from 'crypto'
import jwt from 'jsonwebtoken'
import { JWT } from 'next-auth/jwt'
import { User, UserTokensData } from 'types/auth'

type TokenPayload = AccessTokenPayload & { expires: number; email: string }

export function generateCodeVerifier(): string {
  const array = new Uint8Array(32)
  window.crypto.getRandomValues(array)
  return Array.from(array)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

export function clientId() {
  if (!process.env.LPRX_AUTH_CLIENT_ID) {
    throw Error('No client ID is present!')
  }
  return process.env.LPRX_AUTH_CLIENT_ID
}

// Function to compute SHA-256 hash of a string
const sha256 = (buffer: Buffer): Buffer => crypto.createHash('sha256').update(buffer).digest()

// Function to base64url encode a buffer
const base64Encode = (buffer: Buffer): string =>
  buffer.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')

export function generateCodeChallenge(codeVerifier: string): string {
  // Compute SHA-256 hash of the code verifier
  const codeVerifierBuffer = Buffer.from(codeVerifier)
  const hashed = sha256(codeVerifierBuffer)
  const hash = base64Encode(hashed)
  return hash
}

export function oAuth2Endpoint(path: string) {
  return `${process.env.NEXT_PUBLIC_LPRX_AUTH_URL}oauth2/${path}`
}

interface AuthorizedUserByCodePayload {
  access_token: string
  id_token: string
  refresh_token: string
  token_type: 'Bearer'
  expires_in: number
}

interface AccessTokenPayload {
  'custom:distributor_id': string
  'custom:stripe_sub_id': string
  'custom:stripe_customer_id': string
  'custom:user_role': 'admin' | 'distributor' | 'user'
  'custom:logo': string
  'custom:current_period_end': string
  'custom:business': string
  'custom:professional_type': 'healthcare' | 'wellness'
  'custom:meal_planner_setup': string
  client_id: string
  email: string
  username: string
  family_name: string
  given_name: string
  sub: string
}

interface TokenSetParameters {
  access_token?: string
  token_type?: string
  id_token?: string
  refresh_token?: string
  scope?: string

  expires_at?: number
  session_state?: string

  [key: string]: unknown
}

export const decodeTokens = (tokenSet: TokenSetParameters): TokenPayload => {
  if (!tokenSet.access_token) {
    throw new Error('Missing access token!')
  }

  if (!tokenSet.id_token) {
    throw new Error('Missing ID token!')
  }

  const decodedAccess = jwt.decode(tokenSet.access_token) as AccessTokenPayload
  const decodedID = jwt.decode(tokenSet.id_token) as { email: string }
  return { ...decodedAccess, email: decodedID.email, expires: tokenSet.expires_at || 0 }
}

export async function authorizeUserByCode({
  code,
  codeVerifier,
}: {
  code: string
  codeVerifier: string
}): Promise<TokenSetParameters | null> {
  const response = await fetch(oAuth2Endpoint('token'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: clientId(),
      code,
      code_verifier: codeVerifier,
    }),
  })

  if (!response.ok) {
    return null
  }
  const tokenResponse = (await response.json()) as AuthorizedUserByCodePayload

  return {
    ...tokenResponse,
    expires_at: Date.now() + 4 * 1000, // convert to miliseconds
  }
}

export function crateIntercomUserHash(userName: string) {
  const secretKey = process.env.INTERCOM_SECRET || ''
  return crypto.createHmac('sha256', secretKey).update(userName).digest('hex')
}

export async function refreshAccessToken(token: JWT): Promise<User & UserTokensData> {
  const response = await fetch(oAuth2Endpoint('token'), {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: new URLSearchParams({
      grant_type: 'refresh_token',
      refresh_token: token.refreshToken,
    }),
  })

  const refreshedTokens = (await response.json()) as AuthorizedUserByCodePayload

  if (!response.ok) {
    throw new Error()
  }

  const newTokens = {
    ...refreshedTokens,
    expires_at: Date.now() + refreshedTokens.expires_in * 1000, // convert to miliseconds
  } as TokenSetParameters

  const decodedToken = decodeTokens(newTokens)

  const role = decodedToken['custom:user_role']
  const isAdmin = role === 'admin'

  return {
    id: decodedToken.sub,
    email: decodedToken.email,
    role: decodedToken['custom:user_role'],
    hasSubscription:
      decodedToken['custom:user_role'] === 'admin'
        ? true
        : Number(decodedToken['custom:current_period_end'] || 0) > Date.now() / 1000, // compare two unix epoch seconds values
    subscriptionExpiresAt: Number(decodedToken['custom:current_period_end'] || 0) * 1000,
    stripeCustomerId: decodedToken['custom:stripe_customer_id'],
    stripeSubId: decodedToken['custom:stripe_sub_id'],
    expiresAt: newTokens.expires_at || 0,
    refreshToken: newTokens.refresh_token || '',
    userName: decodedToken.username,
    name: `${decodedToken.given_name} ${decodedToken.family_name}`,
    intercomHash: crateIntercomUserHash(decodedToken.username),
    hasMealPlannerSetup: isAdmin || decodedToken['custom:meal_planner_setup'] === 'true',
    ...(!isAdmin && { professionalType: decodedToken['custom:professional_type'] }),
  }
}
