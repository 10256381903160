import { useEffect } from 'react'
import { generateCodeVerifier } from 'api/services/authService'
import { useLoginCallback } from 'hooks/useLoginCallback'
import Cookies from 'js-cookie'
import { GetServerSidePropsContext } from 'next'
import { getToken } from 'next-auth/jwt'
import { signIn } from 'next-auth/react'
import { Routes } from 'constants/common/routes'
import { Icon } from 'components/ui/Icon'
import { serverSideTranslations } from 'utils/i18n'

const SignIn = () => {
  useLoginCallback()

  useEffect(() => {
    Cookies.set('codeVerifier', generateCodeVerifier())
    void signIn('lprx-auth')
  })

  return (
    <div className="flex h-[100vh] w-[100%] items-center justify-center">
      <Icon.Loader2 className="animate-spin" />
    </div>
  )
}

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
  const token = await getToken({ req: context.req })

  // If the user is already logged in, redirect.
  // Note: Make sure not to redirect to the same page
  // To avoid an infinite loop!
  if (token) {
    const destination = decodeURIComponent((context.query?.callbackUrl as string) || Routes.Home())
    return {
      redirect: {
        destination,
        permanent: false,
      },
    }
  }

  return {
    props: {
      ...(await serverSideTranslations(context, ['common'])),
    },
  }
}

export default SignIn
